:root { --primary: #33202A; }
::-webkit-scrollbar{ display: none; }

html, body { scrollbar-width: none; margin: 0; font-family: Alata-Regular; font-size: 1em; background: #0D1117; }
input, button, textarea { font-family: Alata-Regular; font-size: 1em; }
h1, h2, h3, h4, h5, h6 { margin: 0; font-size: 1em; font-weight: 400; }
p { margin: 0 }
a { text-decoration: none; }

.noselect { -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }

@font-face {
    font-family: "Alata-Regular";
    src: url('fonts/Alata-Regular.ttf');
    src: url('fonts/Alata-Regular.ttf?#iefix') format('embedded-opentype'),
        url('fonts/Alata-Regular.ttf') format('woff2'),
        url('fonts/Alata-Regular.ttf') format('woff'),
        url('fonts/Alata-Regular.ttf') format('truetype'),
        url('fonts/Alata-Regular.ttf') format('svg');
}

@media (max-width: 1024px) {
    body { background-size: 5vw; background-position: 0px 0px; }
    .underline { text-decoration: underline; }
    .uppercase { text-transform: uppercase; }
    .text-center { text-align: center; }
    .text-justify { text-align: justify; }
    .block { margin: 0 auto; height: 70vh; padding: 0 220px; }
    .block-center { height: 100%; display: flex; justify-content: center; align-items: center; }
    .block-46 { width: 100%; display: grid; grid-template-columns: 40% 60% }
    .btn { padding: 0px; background: transparent; color: #E88873; font-size: 1em; border: none; transition: .2s; border-bottom: 1px solid transparent; }
    .btn:hover { border-bottom: 1px solid #E88873; cursor: pointer; }
    .btn-cta { padding: 10px 64px; background: #A72608; color: #fff; font-size: 1.6em; font-weight: 700; border: none; border-radius: 12px; transition: .2s; font-family: Abel-Regular; color: #000; }
    .btn-cta:hover { background: #761A05; cursor: pointer; }
}
@media (min-width: 1024px) {
    body { background-size: 5vw; background-position: 0px 0px; }
    .underline { text-decoration: underline; }
    .uppercase { text-transform: uppercase; }
    .text-center { text-align: center; }
    .text-justify { text-align: justify; }
    .block { margin: 0 auto; height: 70vh; padding: 0 220px; }
    .block-center { height: 100%; display: flex; justify-content: center; align-items: center; }
    .block-46 { width: 100%; display: grid; grid-template-columns: 40% 60% }
    .btn { padding: 0px; background: transparent; color: #fff; font-size: 1em; border: none; transition: .2s; border-bottom: 1px solid transparent; }
    .btn-color { color: #E88873; }
    .btn:hover { border-bottom: 1px solid #fff; cursor: pointer; }
    .btn-color:hover { border-bottom: 1px solid #E88873; cursor: pointer; }
    .btn-cta { padding: 10px 64px; background: #A72608; color: #fff; font-size: 1.6em; font-weight: 700; border: none; border-radius: 12px; transition: .2s; font-family: Abel-Regular; color: #000; }
    .btn-cta:hover { background: #761A05; cursor: pointer; }
}