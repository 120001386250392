.copyIcon {
    color: #e8eaed;
}

.btnLaunch { padding: 7px 24px; height: 40px; background: #2D2D31; color: #fff; font-size: 1.1em; border: none; border-radius: 6px; transition: .2s; }
.btnLaunch:hover { background: #E88873; color: #000; cursor: pointer; }

@media (max-width: 1024px) {}

@media (min-width: 1024px) {

    .createPanel {
        padding: 20px;
        width: 320px;
        //background: green;
    }

    .createTitle {
        font-size: 2em;
        color: #fff;
    }

    .createPanelButton {
        margin-bottom: 10px;
        padding: 8px 18px;
        width: 320px;
        background: transparent;
        color: #eee;
        font-size: 1.6em;
        text-align: left;
        border: none;
        //border: 0px solid #eeeeee40;
        border-radius: 4px;
        transition: .4s;
    }

    .createNextButton {
        margin-top: 40px;
        margin-bottom: 10px;
        padding: 8px 18px;
        width: 320px;
        background: transparent;
        color: #E88873;
        font-size: 1.6em;
        border: none;
        border: 1px solid #E8887340;
        border-radius: 4px;
        transition: .4s;
    }

    .createNextButton:hover {
        border: 1px solid #E88873;
        cursor: pointer;
    }

    .createBackButton {
        padding: 8px 18px;
        width: 320px;
        background: transparent;
        color: #eee;
        font-size: 1.6em;
        border: none;
        border: 1px solid #eeeeee40;
        border-radius: 4px;
        transition: .4s;
    }

    .createBackButton:hover {
        border: 1px solid #eee;
        cursor: pointer;
    }

    .joinLink {
        margin: 100px auto;
        width: 1400px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        background: #2D2D31;
        color: #bbb;
        font-size: 0.9em;
        border: none;
        border-radius: 6px;
        transition: .2s;
    }

    .joinLink:hover {
        color: #eee;
        cursor: pointer;
    }

    .joinLinkBtnCopy {
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        background: #2D2D31;
        font-size: 0.9em;
        border: none;
        border-radius: 6px;
        transition: .2s;
    }

    .joinLinkBtnCopySpan {
        color: #bbb;
        cursor: pointer;
        transition: .2s;
    }

    .joinLinkBtnCopySpan:hover {
        color: #eee;
        cursor: pointer;
    }

    .createTrackBlockHeader { display: grid; grid-template-columns: 84px 500px 150px auto 54px 10px 84px; height: 40px; }

    .createTrackBlock {
        padding-top: 200px;
        margin-bottom: 10px;
        padding: 16px;
        background: #202124;/*#1b1b1d*/
        color: #fff;
        border-radius: 10px;
    }

    .container {
        position: relative;
        margin: 0;
        font-size: 1.3em;
        line-height: 40px;
    }

    .createTrackContainer {
        padding-top: 100px !important;
    }

    h1 {
        margin: 30px;
        font-size: 1.2em;
        text-align: center;
        color: #fff;
        text-transform: capitalize;
    }

    .active {
        background: red;
        border: 6px solid white;
    }

}
  