@media (max-width: 1024px) {}

@media (min-width: 1024px) {

    .btn {
        position: absolute;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background: #2D2D31;
        color: #fff;
        font-size: 1.2em;
        border: none;
        border-radius: 6px;
        transition: .2s;
    }

    .btn:hover {
        background: #E88873;
        color: #000;
        cursor: pointer;
    }

}
  